import { createGlobalStyle } from 'styled-components';
import Theme from './theme';
import backdrop from '../assets/images/backdrop.jpg';

const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;	
		font-family: 'ZillaSlab', serif;
		font-size: 20px;
		user-select: none;
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
		background-image: url(${backdrop});
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}

	h1 {
		font-size: 2.2rem;
		margin-top: 0;
	}

	h2 {
		font-weight: 200;
		margin-top: 0;
	}

	P {
		margin-top: 0;
	}

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		p {
			font-size: 1rem;
		}
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		p {
			font-size: 1.7rem;
		}
	}

	/* // Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		p {
			font-size: 2rem;
		}
	}

	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){   
		p {
			font-size: 2rem;
		}
	} */

	* {
		box-sizing: border-box;
	}

	#root {
		height: 100%;
	}

	.d-none {
		display: none!important;
	}

	/* custom scroll bar */

	/* width */
	::-webkit-scrollbar {
		width: 7.5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${Theme.colors.neutral};
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background:  ${Theme.colors.neutralLightest}; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}


	// timer bar

	#timer-bar{
	height: 15px;
	position: relative;

	transition: opacity 1s;
	}

	#timer-bar-container{
	position: absolute;

	top:50%;
	width: 100%;
	height: 80%;

	transform: translate(0, -50%);

	background-color: grey;
	}

	#timer-bar-fill{
	position: absolute;
	width: 100%;
	height: 100%;

	background-color: white;

	transform-origin: center right;
	transition: transform 10s linear;
	}

	.fill-idle{
	background-color: grey!important;
	}

	.fill-decreasing{
	//transform: scaleX(0)!important;
	animation-name: countdown;
	animation-duration: 30s;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
	}

	.fill-decreasing-end{
	//transform: scaleX(0)!important;
	animation-name: countdown;
	animation-duration: 30s;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
	}

	.fill-paused{
	animation-play-state: paused;
	}

	@keyframes countdown {
	0%   {transform:scaleX(100%);}
	50%  {background:white;}     
	100% {transform:scaleX(0); background:${Theme.colors.primary}}
	}

	// decisions

	#host-decisions{
    position: absolute;
    width: 100%;
    height: 33%;
    bottom: 0;
    transform: translate(0, 0);
    background-color: black;
    font-family: 'Oswald', sans-serif;
}

.host-decision{
    /* font-size: 3.5vw; */
    text-shadow: 0.10em 0 #262626;
    color: white;
    text-transform: uppercase;
}

.host-decision-nr{
    text-shadow: 0.10em 0 grey;
    color: red;
    text-transform: uppercase;
    padding-right: 15px;
}

.db-id{
    color: ${Theme.colors.primary};
    text-shadow: 0.10em 0 #262626;
}

.stemmen {
     background-color: grey;
     text-shadow: 0.10em 0 #262626;
}

.decision-row {
    height: 40%;
}

.decision-lost{
    opacity: 0.5;
}

`;

export default GlobalStyle;
