import styled from 'styled-components';
import Theme from '../../style/theme';
import { Button } from '../components/Button';
import { SideContainer } from '../components/SideContainer';
import { Title } from '../components/Title';
import { useTranslation } from 'react-i18next';
import { Logo } from '../components/Logo.jsx';
import { Fragment } from 'react';

export const Landing = () => {

	const {t} = useTranslation();

	return (
		<div id={'pc-host'}>
			<Logo/>
			<SideContainer>

				<Title>{t('pages.landing.title')}</Title>
				<p>
					{t('pages.landing.call_to_action1')}<u>aftrap.annefrank.org</u><br/>
					{t('pages.landing.call_to_action2')}<RoomCode id={'host-roomcode'}>000000</RoomCode><br/>
					{t('pages.landing.note')}
					{/* Ga naar <u>aftrap.annefrank.org</u><br/>
            en vul deze code in:<RoomCode id={'host-roomcode'}>000000</RoomCode><br/>
            Als iedereen is aangemeld kan de game beginnen. */}
				</p>
				<p>{t('pages.landing.description')}</p>
				<p>{t('pages.landing.question')}</p>
				<Button id="host-start-btn" style={{marginBottom: '50px'}}>{t('general.start')}</Button>
			</SideContainer>
		</div>

	);
};

// styled components

const RoomCode = styled.span`
    position: relative;
	font-family: 'Graphik';
	color: ${Theme.colors.primary};
    margin-left: 1rem;
    user-select: text;
padding-top: 0.2rem;
    &:after{
        content: '';
        position: absolute;
        left: -5px; top: 8px; right: -5px; bottom: 2px;
        background-color: ${Theme.colors.neutralLightest};
        z-index:-1;
    }
`;