import { useTranslation } from 'react-i18next';
import { SideContainer } from '../components/SideContainer';
import { Title } from '../components/Title';
import styled from 'styled-components';

export const End = () => {
	const {t} = useTranslation();
	return (
		<EndContainer id={'pc-end'} style={{display: 'none'}}>
			<Title>{t('pages.playing.thanks')}</Title>
		</EndContainer>
	);
};

const EndContainer = styled.div`

	position: absolute;
	inset:0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

