import styled from 'styled-components';
import { Button } from '../components/Button';
import { Title } from '../components/Title';

import thumbnail0 from '../../assets/images/Thumbnail_0.jpg';
import thumbnail1 from '../../assets/images/Thumbnail_1.jpg';
import thumbnail2 from '../../assets/images/Thumbnail_2.jpg';
import thumbnail3 from '../../assets/images/Thumbnail_3.jpg';
import thumbnail4 from '../../assets/images/Thumbnail_4.jpg';
import thumbnail5 from '../../assets/images/Thumbnail_5.jpg';
import thumbnail6 from '../../assets/images/Thumbnail_6.jpg';
import thumbnail7 from '../../assets/images/Thumbnail_7.jpg';
import thumbnail8 from '../../assets/images/Thumbnail_8.jpg';
import thumbnail9 from '../../assets/images/Thumbnail_9.jpg';

import Theme from '../../style/theme';
import { useTranslation } from 'react-i18next';



const data = [
	{
		label: 'online',
		thumbnail: thumbnail0,
	},
	{
		label: 'aanmeldformulier',
		thumbnail: thumbnail7,
	},
	{
		label: 'achterindebus',
		thumbnail: thumbnail2,
	},
	{
		label: 'dugout',
		thumbnail: thumbnail1,
	},
	{
		label: 'decornervlag',
		thumbnail: thumbnail3,
	},
	{
		label: 'penalty',
		thumbnail: thumbnail4,
	},
	{
		label: 'kleedkamer',
		thumbnail: thumbnail8,
	},
	{
		label: 'tafelvoetbal',
		thumbnail: thumbnail5,
	},
	{
		label: 'deparkeerplaats',
		thumbnail: thumbnail6,
	},
	{
		label: 'rodekaart',
		thumbnail: thumbnail9,
	},
];

export const Select = () => {
	const {t} = useTranslation();
	
	return (
		<Container id="pc-select" style={{display: 'none'}}>
			<Header>
				<Title>{t('pages.select.title')}</Title>
				<div style={{display:'flex', marginLeft: 'auto'}}>
					<Button id="outro-btn" style={{marginBottom: '50px', marginLeft:'auto'}}>{t('general.close')}</Button>
				</div>
			</Header>

			<Grid>
				{data.map((value, index) => 
					<Scene key={`scene-button-${index}`} url={value.thumbnail} id={`host-scene-btn-${index}`}>
						<SceneLabel>{`>> ${t(`pages.select.${value.label}_title`)} <<`}</SceneLabel>
					</Scene>
				)}
			</Grid>

		</Container>
	);
};

// styled components

const Container = styled.div`

	margin-block: 50px;
	margin-inline: 50px;

`;

const Header = styled.div`
	display:flex;
	align-items: flex-start;
`;

const Grid = styled.div`

	margin-inline: 50px;

	display: grid;
	grid-template-rows: repeat(3, 1fr);
	grid-template-columns: repeat(12, 1fr);
	gap: 50px;

	& > * {
		grid-column: auto / span 4;
	}

	& :nth-child(8n + 4), 
	& :nth-child(8n + 5),
	& :nth-child(8n + 6),
	& :nth-child(8n + 7){
		grid-column: auto / span 3;
	}

	/* & :nth-child(4n + 5) {
		grid-column: auto / span 3;
		border: solid 2px red;
	} */
`;

const Scene = styled.button`
	position: relative;
	border: none;
	height: 200px;

	background-image: url(${p => p.url});
	background-size: cover;
	background-position: center;

	cursor: pointer;

	box-shadow: 5px 5px ${Theme.colors.neutralDarker};

	transition: all .4s;

	&:hover > div{
		opacity: 1;
	}
	&:hover{
		transform: translate(-5px,-5px);
		box-shadow: 10px 10px ${Theme.colors.neutralDarker};
	}

	height: 25vh;
	max-height: 50vw;
`;

const SceneLabel = styled.div`
	position: absolute;
	left:0;bottom:0;right:0;
	background-color: ${Theme.colors.neutralDarkest};
	font-family: 'ZillaSlab';
	font-size: 1.5rem;
	white-space: nowrap;
	color: ${Theme.colors.primary};
	pointer-events: none;
	opacity: 0;
	padding-bottom: 6px;

	@media (max-width: ${Theme.responsive.media.xl}){
		font-size: 1.25rem;
	}

`;