
export default {

	'general': {
		'start' : 'start',
		'close' : 'afsluiten'
	},
	'pages': {
		'landing':{
			'title': ' aanmelden en uitleg ',
			'call_to_action1' :'Ga naar ',
			'call_to_action2' :'en vul deze code in:',
			'note': 'Als iedereen is aangemeld kan de game beginnen.',
			'description' : 'Je gaat straks verschillende scenario’s zien op het grote scherm. Op een gegeven moment krijg jij de keuze hoe de hoofdpersoon moet reageren. De keuze maak je op je telefoon of tablet. Je hebt hier kort de tijd voor.',
			'question' : 'Wat zou jij doen in elke situatie?'
		},
		'select':{
			'title': 'Kies een scenario',
			'online_title': 'Online',
			'aanmeldformulier_title': 'Aanmeldformulier',
			'achterindebus_title': 'Achter in de bus',
			'dugout_title': 'Dugout',
			'decornervlag_title': 'De cornervlag',
			'penalty_title': 'Penalty',
			'kleedkamer_title': 'Kleedkamer',
			'tafelvoetbal_title': 'Tafelvoetbal',
			'deparkeerplaats_title': 'De parkeerplaats',
			'rodekaart_title': 'Rodekaart',

		},
		'video': {
			0: {
				'text1': 'Delen met de klas',
				'text2': 'Melden bij mentor',
				'text3': 'Liken en reageren',
				'text4': 'Afkeuren in comment',
			},
			1: {
				'text1': 'Noni gelijk geven',
				'text2': 'Fatma aanmoedigen',
				'text3': 'Fatma ontmoedigen',
				'text4': 'Iedereen uitnodigen',
			},
			2: {
				'text1': 'Boos worden',
				'text2': 'Jesse steunen',
				'text3': 'Niets doen',
				'text4': 'Mee lachen',
			},
			3: {
				'text1': 'Ook schreeuwen',
				'text2': 'Niets doen',
				'text3': 'Denise kalmeren',
				'text4': 'Er iets van zeggen',
			},
			4: {
				'text1': 'Doorspelen',
				'text2': 'Stoppen met spelen',
				'text3': 'Scheids roepen',
				'text4': 'Niets doen',
			},
			5: {
				'text1': 'Haar de bal geven',
				'text2': 'Snel kiezen',
				'text3': 'Hem gelijk geven',
				'text4': 'Niemand kiezen',
			},
			6: {
				'text1': 'Michiel kalmeren',
				'text2': 'Melden bij docent',
				'text3': 'Lois troosten',
				'text4': 'Verder vieren',
			},
			7: {
				'text1': 'Verder spelen',
				'text2': 'Bram troosten',
				'text3': 'Er iets van zeggen',
				'text4': 'Mee lachen',
			},
			8: {
				'text1': 'Niets doen',
				'text2': 'Bal niet geven',
				'text3': 'Bal schieten',
				'text4': 'Er iets van zeggen',
			},
		},
		'videoend': {
			'question' : 'andere keuzemogelijkheid bekijken?',
			'back' : 'terug naar hoofdmenu'
		},
		'connected':{
			'title':'Uitleg',
			'connected_to':'Verbonden met sessie:'
		},
		'connecting':{
			'message': 'Verbinden...'
		},
		'join':{
			'title':'WELKOM',
			'description': 'Vul de code in en doe mee met de game! De code is te vinden op het grote scherm.',
			'enter': 'ENTER',
			'list': 'VRAGENLIJST'
		},
		'playing':{
			'choose': 'maak een keuze!',
			'watch': 'Kijk het scenario!',
			'failed':'Verbinding mislukt, check de code en probeer het nogmaals.',
			'wait':'Wacht tot er een scenario wordt afgespeeld!',
			'watch_screen':'Kijk op het scherm voor het resultaat!',
			'thanks':'Bedankt voor het spelen!',
			'connection':'Verbinding met sessie verbroken!',
			'alert': 'Voer een code in',
			'choosed': 'Je hebt een keuze gemaakt'
		},
		'footer':{
			'connected':'verbonden spelers:'
		}
	},

};